<template>
  <div>
    <div class="content-box">
        <div class="form-label-title">
            垃圾中转站信息
        </div>
        <div class="content-main">
            <ayl-table :table="table"></ayl-table>
        </div>
      <div class="tac" style="margin-top: 40px">
        <el-button type="success" class="btn-success-hw" @click="submitForm()">整体提交</el-button>
      </div>
    </div>
    <el-dialog
    title="审核结果"
    :visible.sync="dialogVisible"
    width="30%"
    center
    :before-close="handleClose">
    <el-form :model="form" :rules="rules" ref="form" :inline="true" :label-position="$config.labelPosition">
        <el-form-item label="审核结果:" style="width: 100%">
            <el-radio v-model="form.radio" label="通过">通过</el-radio>
            <el-radio v-model="form.radio" label="不通过">不通过</el-radio>
        </el-form-item>
        <el-form-item label="审核意见" style="width: 100%">
            <el-input v-model="form.cargoArrivalNumber" placeholder="请输入" maxlength="7"></el-input>
        </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
        <el-button type="success" class="btn-success-hw" @click="dialogVisible = false">确 定</el-button>
        <el-button @click="dialogVisible = false">取 消</el-button>
    </span>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    data () {
      const vm = this
      return {
          dialogVisible: false,
          form: {
            cargoArrivalNumber: '测试',
            radio: null,
          },
          rules: {},
          table: {
          api: vm.$api.OrderList,
          query: {
            type: 1,
            orderType: null,
            carBlong: null,
            queryContent: null,
          },
          // 是否显示分页
          // hidePagination: true,
          // 表格查询的条件组件
          searchData: [],
          columns: [
            {
              title: '序号',
              width: '50px',
              $index: 'index',
            },
            {
              title: '垃圾中转站名称',
              render (h, ctx) {
                return h('span', [
                  h('span', {
                    class: {
                      'table-view-hw': true,
                    },
                    on: {
                      click: vm.wasteTransferStationName.bind(this, ctx.row)
                    }
                  }, ctx.row.orderId||'- -')
                ])
              }
            }, {
              title: '中转站类型',
              width: '180px',
              key: 'phone',
            }, {
              title: '地址',
              key: '',
              filter: 'str2ymd',
              width: '160px',
            }, {
              title: '有无公厕',
              width: '180px',
              key: '',
            }, {
              title: '变更类型',
              width: '180px',
              key: '',
            }, {
              title: '状态',
              width: '180px',
              key: '',
            },{
              title: '审核意见',
              key: '',
            },{
              title: '操作',
              width: '65px',
              render (h, ctx) {
                return h('span', [
                  h('span', {
                    class: {
                      'table-view-hw': true,
                    },
                    on: {
                      click: vm.wasteTransferStationClick.bind(this, ctx.row)
                    }
                  }, '审核'),
                ])
              }
            }]
        },
        
      }
    },
    methods: {
        wasteTransferStationName() {},
        wasteTransferStationClick() {
            this.dialogVisible = true
        },
        handleClose(done) {
            this.$confirm('确认关闭？')
            .then(_ => {
                done();
            })
            .catch(_ => {});
        },
        submitForm() {},
    },

    async mounted() {
        await this.$search(this.table)
    },
  }
</script>

<style lang='sass' scoped>
.form-label-title
    color: #2E3033
    font-size: 16px
    margin: 0 0 10px 0
    padding-left: 10px
    border-left: 2px solid #1ABC9C

.form-label-test
    font-size: 14px
    color: rgba(126,129,135,1)
    line-height: 46px

/deep/.form-label-base
    margin: 20px 0 10px
</style>
